import Cookies from 'js-cookie'
import { includes, values } from 'ramda'

import { AvgConsentLevel } from '@app-enums'
import { CONSENT_LEVEL_COOKIE_KEY, cookieExpires } from './cookie-settings.config'

const getCookieExpiresForConsentLevel = (consentLevel: AvgConsentLevel): number => {

    // Use strict comparison, no switch case:

    if (consentLevel === AvgConsentLevel.ANALYTICS) {
        return cookieExpires.ONE_YEAR
    }

    if (consentLevel === AvgConsentLevel.NECESSARY) {
        return cookieExpires.ONE_MONTH
    }

    if (consentLevel === AvgConsentLevel.MARKETING) {
        return cookieExpires.ONE_MONTH
    }

    // Default for now:
    return cookieExpires.ONE_MONTH
}

export const isValidConsentLevel = (
    consentLevel: AvgConsentLevel,
): boolean => includes(consentLevel, values(AvgConsentLevel))

export const setConsentLevel = (consentLevel: AvgConsentLevel): boolean => {
    if (! isValidConsentLevel(consentLevel)) {
        return false
    }

    Cookies.set(CONSENT_LEVEL_COOKIE_KEY, consentLevel.toString(), {
        expires: getCookieExpiresForConsentLevel(consentLevel),
    })

    return true
}

export const getConsentLevel = (): number | undefined => {
    const stringValue = Cookies.get(CONSENT_LEVEL_COOKIE_KEY)
    try {
        return stringValue ? JSON.parse(stringValue) : undefined
    } catch (error) {
        return undefined
    }
}
